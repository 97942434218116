var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "tidycalPopup",
        size: "xl",
        scrollable: "",
        "hide-footer": "",
        "header-class": "py-0",
        "no-close-on-backdrop": "",
        "no-close-on-esc": ""
      },
      on: {
        change: function(v) {
          _vm.$emit("change", v)
        }
      },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c("div", {
        attrs: {
          id: "tidycal-embed",
          "data-path": _vm.path + "/" + _vm.bookingType,
          "data-show-avatar": ""
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }