var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "my-3" },
            [_c("b-col", [_c("h2", [_vm._v("Contact Bookkeeper")])])],
            1
          ),
          !_vm.showConfirmation
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "form",
                      {
                        ref: "contactForm",
                        attrs: { action: "" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.contact()
                          }
                        }
                      },
                      [
                        _vm.companies && _vm.companies.length > 1
                          ? _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Company",
                                          "label-align": "left"
                                        }
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: { options: _vm.companies },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "first",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "b-form-select-option",
                                                      {
                                                        attrs: {
                                                          value: null,
                                                          disabled: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "-- Please select a company --"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            1351801080
                                          ),
                                          model: {
                                            value: _vm.form.company_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "company_id",
                                                $$v
                                              )
                                            },
                                            expression: "form.company_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Subject",
                                      "label-align": "left"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "Subject of Message"
                                      },
                                      model: {
                                        value: _vm.form.subject,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "subject", $$v)
                                        },
                                        expression: "form.subject"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Message",
                                      "label-align": "left"
                                    }
                                  },
                                  [
                                    _c("b-form-textarea", {
                                      attrs: {
                                        placeholder: "Your Message",
                                        rows: "7",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.form.content,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "content", $$v)
                                        },
                                        expression: "form.content"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-overlay",
                                  {
                                    staticClass: "d-inline-block",
                                    attrs: {
                                      show: _vm.loading,
                                      "spinner-small": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "success"
                                        }
                                      },
                                      [_vm._v("Send Message")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.showConfirmation
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("p", [
                        _vm._v("Your submission has been received. Thank you.")
                      ]),
                      _c(
                        "b-button",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.showConfirmation = false
                            }
                          }
                        },
                        [_vm._v("Send Another Message")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.selectedCompany && _vm.selectedCompany.calendar
            ? _c(
                "div",
                [
                  _c("hr", {
                    staticStyle: { "border-top": "1px solid white" }
                  }),
                  _c("div", { staticClass: "mb-3" }, [_vm._v("or")]),
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.loading } },
                    [
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.showTidycal(
                                        _vm.selectedCompany.calendar,
                                        "30-minute-call"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("b-icon-phone"),
                                  _vm._v(
                                    " Schedule A Call With Your Bookkeeper "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.showTidycal(
                                        _vm.selectedCompany.calendar,
                                        "30-minute-screenshare"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("b-icon-display"),
                                  _vm._v(
                                    " Schedule A Screenshare With Your Bookkeeper "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("TidyCal", {
        attrs: {
          show: _vm.showTidycalPopup,
          path: _vm.bookingPath,
          "booking-type": _vm.bookingType
        },
        on: {
          change: function(v) {
            this$1.showTidycalPopup = v
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }